/**
 * Front door service
 * The front door service API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';

// @ts-ignore
import { CreateFrontDoorDto } from '../model/createFrontDoorDto';
// @ts-ignore
import { FindBySlugDto } from '../model/findBySlugDto';
// @ts-ignore
import { FrontDoorDto } from '../model/frontDoorDto';
// @ts-ignore
import { GenerateSlugDto } from '../model/generateSlugDto';
// @ts-ignore
import { IdsDto } from '../model/idsDto';
// @ts-ignore
import { ReferPatientDto } from '../model/referPatientDto';
// @ts-ignore
import { RequestTransportDto } from '../model/requestTransportDto';
// @ts-ignore
import { RequestedTransportDto } from '../model/requestedTransportDto';
// @ts-ignore
import { SlugDto } from '../model/slugDto';
// @ts-ignore
import { UpdateFrontDoorDto } from '../model/updateFrontDoorDto';
// @ts-ignore
import { UserSettingsDto } from '../model/userSettingsDto';
// @ts-ignore
import { ValidateSlugDto } from '../model/validateSlugDto';
// @ts-ignore
import { WaitingRoomDto } from '../model/waitingRoomDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class FrontDoorService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param createFrontDoorDto 
   */
  public async frontDoorControllerCreate(xTENANTID: string, createFrontDoorDto: CreateFrontDoorDto, ): Promise<FrontDoorDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerCreate.');
    }
    if (createFrontDoorDto === null || createFrontDoorDto === undefined) {
      throw new Error('Required parameter createFrontDoorDto was null or undefined when calling frontDoorControllerCreate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door`,
      data: createFrontDoorDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param clinicId Clinic id
   */
  public async frontDoorControllerFindAll(xTENANTID: string, clinicId?: string, ): Promise<Array<FrontDoorDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerFindAll.');
    }

    let localVarQueryParameters: any = {};
    if (clinicId !== undefined && clinicId !== null) {
      localVarQueryParameters['clinicId'] = clinicId;
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door`,
      params: localVarQueryParameters,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param idsDto 
   */
  public async frontDoorControllerFindByIds(xTENANTID: string, idsDto: IdsDto, ): Promise<Array<FrontDoorDto>> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerFindByIds.');
    }
    if (idsDto === null || idsDto === undefined) {
      throw new Error('Required parameter idsDto was null or undefined when calling frontDoorControllerFindByIds.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/by-ids`,
      data: idsDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async frontDoorControllerFindOne(xTENANTID: string, id: string, ): Promise<FrontDoorDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerFindOne.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerFindOne.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param findBySlugDto 
   */
  public async frontDoorControllerFindOneBySlug(xTENANTID: string, findBySlugDto: FindBySlugDto, ): Promise<FrontDoorDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerFindOneBySlug.');
    }
    if (findBySlugDto === null || findBySlugDto === undefined) {
      throw new Error('Required parameter findBySlugDto was null or undefined when calling frontDoorControllerFindOneBySlug.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/slug`,
      data: findBySlugDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param generateSlugDto 
   */
  public async frontDoorControllerGenerateSlug(xTENANTID: string, generateSlugDto: GenerateSlugDto, ): Promise<SlugDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerGenerateSlug.');
    }
    if (generateSlugDto === null || generateSlugDto === undefined) {
      throw new Error('Required parameter generateSlugDto was null or undefined when calling frontDoorControllerGenerateSlug.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/slug/generate`,
      data: generateSlugDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   */
  public async frontDoorControllerGetMyFrontDoorSettings(xTENANTID: string, ): Promise<UserSettingsDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerGetMyFrontDoorSettings.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/me`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   * @param referPatientDto 
   */
  public async frontDoorControllerReferPatient(xTENANTID: string, id: string, referPatientDto: ReferPatientDto, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerReferPatient.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerReferPatient.');
    }
    if (referPatientDto === null || referPatientDto === undefined) {
      throw new Error('Required parameter referPatientDto was null or undefined when calling frontDoorControllerReferPatient.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/refer`,
      data: referPatientDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async frontDoorControllerRemove(xTENANTID: string, id: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerRemove.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerRemove.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.delete({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   * @param requestTransportDto 
   */
  public async frontDoorControllerRequestTransport(xTENANTID: string, id: string, requestTransportDto: RequestTransportDto, ): Promise<RequestedTransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerRequestTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerRequestTransport.');
    }
    if (requestTransportDto === null || requestTransportDto === undefined) {
      throw new Error('Required parameter requestTransportDto was null or undefined when calling frontDoorControllerRequestTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-transport`,
      data: requestTransportDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   */
  public async frontDoorControllerRequestTreatReleaseTransport(xTENANTID: string, id: string, ): Promise<RequestedTransportDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerRequestTreatReleaseTransport.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerRequestTreatReleaseTransport.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-treat-release-transport`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * 
   * Creates Patient John Doe, Creates WaitingRoom and Creates visit for provider to see
   * @param xTENANTID 
   * @param id 
   */
  public async frontDoorControllerRequestVisit(xTENANTID: string, id: string, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerRequestVisit.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerRequestVisit.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-visit`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param id 
   * @param updateFrontDoorDto 
   */
  public async frontDoorControllerUpdate(xTENANTID: string, id: string, updateFrontDoorDto: UpdateFrontDoorDto, ): Promise<FrontDoorDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerUpdate.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling frontDoorControllerUpdate.');
    }
    if (updateFrontDoorDto === null || updateFrontDoorDto === undefined) {
      throw new Error('Required parameter updateFrontDoorDto was null or undefined when calling frontDoorControllerUpdate.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      data: updateFrontDoorDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param validateSlugDto 
   */
  public async frontDoorControllerValidateSlug(xTENANTID: string, validateSlugDto: ValidateSlugDto, ): Promise<SlugDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling frontDoorControllerValidateSlug.');
    }
    if (validateSlugDto === null || validateSlugDto === undefined) {
      throw new Error('Required parameter validateSlugDto was null or undefined when calling frontDoorControllerValidateSlug.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/slug/validate`,
      data: validateSlugDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

}
