/**
 * Front door service
 * The front door service API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';

// @ts-ignore
import { FindBySlugDto } from '../model/findBySlugDto';
// @ts-ignore
import { PatientFrontDoorInfoDto } from '../model/patientFrontDoorInfoDto';
// @ts-ignore
import { SelfVisitDto } from '../model/selfVisitDto';
// @ts-ignore
import { WaitingRoomDto } from '../model/waitingRoomDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class FrontDoorPatientInfoService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param xTENANTID 
   * @param findBySlugDto 
   */
  public async patientFrontDoorInfoControllerFindOneBySlug(xTENANTID: string, findBySlugDto: FindBySlugDto, ): Promise<PatientFrontDoorInfoDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientFrontDoorInfoControllerFindOneBySlug.');
    }
    if (findBySlugDto === null || findBySlugDto === undefined) {
      throw new Error('Required parameter findBySlugDto was null or undefined when calling patientFrontDoorInfoControllerFindOneBySlug.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/info/slug`,
      data: findBySlugDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * 
   * Used by existing patient to create WaitingRoom and visit for for provider to see
   * @param xTENANTID 
   * @param id 
   * @param selfVisitDto 
   */
  public async patientFrontDoorInfoControllerRequestSelfVisit(xTENANTID: string, id: string, selfVisitDto: SelfVisitDto, ): Promise<WaitingRoomDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling patientFrontDoorInfoControllerRequestSelfVisit.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patientFrontDoorInfoControllerRequestSelfVisit.');
    }
    if (selfVisitDto === null || selfVisitDto === undefined) {
      throw new Error('Required parameter selfVisitDto was null or undefined when calling patientFrontDoorInfoControllerRequestSelfVisit.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/front-door/v1/front-door/info/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-self-visit`,
      data: selfVisitDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

}
